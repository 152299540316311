/* Header */
.document-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0.5rem;
  height: 5.0625rem;
  background: #fff;
  z-index: 20;
  border-bottom: 0.05rem solid var(--grey);
}

/* Logo */
.document-header .logo-container {
  user-select: none;
}
.document-header .logo-container img {
  height: 4rem;
  width: 4rem;
}
.document-header .logo-container .beta {
  background: var(--secondary);
  color: #fff;
  border-radius: 0.25rem;
  padding: 0.25rem;
  font-size: 0.7rem;
  text-transform: uppercase;
}

/* Navigation */
.document-header .navigation .link {
  font-size: 1rem;
  text-transform: uppercase;
  color: #000;
  margin: 0 0.5rem;
  text-decoration: none;
  position: relative;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  user-select: none;
}
.document-header .navigation .link.active {
  color: var(--primary);
}
.document-header .navigation .link.active::before {
  display: block;
  content: "";
  width: 100%;
  height: 0.1rem;
  background: var(--primary);
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(2rem);
}