/* Flex */
.flex {
  display: flex;
}
.wrap {
  flex-wrap: wrap;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.align-center {
  align-items: center;
}
.direction-column {
  flex-direction: column;
}