.disclaimer {
    position: fixed;
    width: 80%;
    left: 50%;
    transform: translateX(-50%);
    bottom: 1rem;
    padding: 1rem;
    background: var(--grey);
    border: 0.1rem solid var(--border-grey);
    border-radius: 0.5rem;
}

.disclaimer i {
    color: #555;
    cursor: pointer;
    font-size: 1.25rem;
    transition: color 0.2s ease;
}

.disclaimer i:hover {
    color: var(--secondary);
}